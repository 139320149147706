import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <br />
      <br />
      <h4>
        <Link to="/">
          Head to Home page <span>&#8605;</span>{" "}
        </Link>
      </h4>
    </Wrapper>
  </div>
)

export default NotFoundPage

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & span {
    font-size: 1.3rem;
  }
`
